import React                   from 'react';
import styled                  from '@emotion/styled';
import { Container, Row, Col } from 'react-grid-system';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import { GiLinkedRings }       from 'react-icons/gi';

import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import SEO         from '@interness/web-core/src/components/modules/SEO/SEO';
import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

import Link         from '@interness/web-core/src/components/elements/Link/Link';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';

const List = styled.div`
    h4 {
        margin-top: 0;
    }
`;

const J = styled.p`
    text-align: justify;
`;

const AtelierPage = (props) => {
  const data = props.data;
  return (
    <>
      <SEO title={'Trauringschmiedekurs'}/>
      <HeaderImage>
        <Img fluid={props.data.headerImages.media[0].file.localFile.childImageSharp.fluid}/>
      </HeaderImage>
      <Spacer />
      <Wrapper>
        <Heading icon={<GiLinkedRings/>} subtitle="Erschaffen Sie Ihre Trau(m)ringe">Trauringschmiedekurs</Heading>
        <article>
          <Spacer height={30}/>
          <Container fluid>
            <Row>
              <Col md={6}>
                <Img fluid={data.trauringkurs.childImageSharp.fluid}/>
                <Spacer/>
                <p>Impressionen vom Kursverlauf: <Link
                  to="/trauringschmiedekurs/ablauf">Galerie</Link></p>
                <p>Ergebnisse unserer Trauring-Schmiedekursteilnehmer: <Link
                  to="/trauringschmiedekurs/ergebnisse">Galerie</Link></p>
                <p>Haben Sie interesse an unserem Trauring-Schmiedekurs? <Link to="/kontakt">Kontaktieren Sie
                  uns</Link></p>
              </Col>
              <Col md={6}>
                <J>
                  Sie wollen gemeinsam mit Ihrem Partner das Symbol Ihrer Liebe mit eigenen Händen anfertigen? Dann
                  nehmen Sie an einem Trauring-Schmiedekurs in unserer Schloss-Galerie teil. Entdecken Sie hier die
                  ursprüngliche Faszination von edlen Metallen wie Silber und Gold. Unter Anleitung der
                  Goldschmiedemeister Elke
                  Reymann und Alfons Spieker formen Sie daraus die Ringe, die Sie ein Leben lang begleiten werden. Ein
                  unvergesslicher Tag.
                </J>
                <List>
                  <h4>Preis</h4>
                  <p>120 € pro Teilnehmer</p>

                  <h4>Kursdauer</h4>
                  <p>Eintägig</p>

                  <h4>Termine</h4>
                  <p>Nach Vereinbarung.</p>

                  <h4>Steinbesatz</h4>
                  <p>Erforderliche Brillanten und Edelsteine sind in großer
                    Auswahl vorhanden.</p>

                  <h4>Gravuren</h4>
                  <p>Eine individuelle Maschinengravur ist im Teilnehmerpreis
                    enthalten. Hand- oder Lasergravuren können gegen Aufpreis
                    eingebracht werden.</p>

                  <h4>Materialkosten</h4>
                  <p>Für das von Ihnen benötigte Edelmetall und den evtl.
                    gewünschten Steinbesatz erstellen wir vorab unverbindlich
                    einen Kostenvoranschlag.</p>
                </List>
              </Col>
            </Row>
          </Container>
        </article>
        <Spacer/>
      </Wrapper>
      <CallToAction description="Vereinbaren Sie noch heute Ihren Termin zur Trauringberatung"
                    button="Zur Terminvereinbarung" link="/terminvereinbarung"
                    imageFileTitle="theme-sonora-call-to-action-background-trauringe"/>
    </>
  )
};

export default AtelierPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "trauringkurs-header"}) {
            ...MediaCollectionHeaderImages
        }
        trauringkurs: file(relativePath: {eq: "trauringkurs/trauringkurs.jpg"}) {
            name
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
